.navbar{
    padding: 10px 10px;
}

.navbar-text{
    color: #fff
}

.nav-link {
    color: #fff !important;
}


.nav-link.active{
   color: #95eaff !important;
}

.navbar-brand {
    font-size: 30px;
    font-weight: bold;
    color: #fff;
    margin-left: 20px;
}

.page-container{
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 0px;
}


.text-paragraph{
    font-size: 20px;
    text-align: justify;
}

.title{
    font-size: 80px;
    font-weight: bold;
    align-content: center;
    color: #00b392;
    margin: 60px 0px;
    text-align: center;
}

.subtitle{
    font-size: 60px;
    font-weight: bold;
    align-content: center;
    color: #bfe4dd;
    text-align: center;
}

.subsection-title{
    font-size: 60px;
    font-weight: bold;
    align-content: center;
    color: #00b392;
    margin: 60px 0px;
    margin-top: 140px;
    text-align: center;
}

.total-patients{
    font-size: 80px;
    font-weight: bold;
    align-content: center;
    color: #232726;
    margin: 60px 0px;
    text-align: center;

}

.patient-field-disabled{
    /*un rosso chiaro e pastelloso*/
    color: #ff8888;
}

.pie-chart{
    width: 65%;
}

.bar-chart{
    width: 90%;
    height: 500px;
}

.chart-title{
    font-size: 24px;
    font-weight: bold;
    color: grey;
}

.carousel {
    height: 70vh;
    margin: 5% 0%;
  }
  
    .carousel-inner {
        width: 100%;
        height: 100%;
    }

    .statistics-card{
        /* black shadow */
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
        /* top and bottom margin */
        margin: 50px 0px; 
        padding-top: 30px;

    }

    .card-title{
        font-size: 32px;
        font-weight: bold;
        color: #00b392;
    }

    .statistics-card-row {
        margin-top: 100px;
        margin-bottom: 100px;
    }

    

    .progress-bar-chart{
        width: 75%;
        height: 50px;
        align-self: center;
    }

    .progress-bar-chart-label {
        font-size: 20px;
        font-weight: bold;
        color: grey;
    }

    .footer{
        bottom: 0;
        width: 100%;
    }

    .last-edit{
        font-size: 12px;
        font-weight: bold;
        color: grey;
    }

    .language-selector{
        font-size: 18px;
        font-weight: bold;
        color: white;
        border: 1px solid white;
        border-radius: 5px;
    }


    .header{
        background-color: #00b392;
        color: white;
        padding: 10px;
        margin-bottom: 20px;
    }

    .header-link {
        color: rgb(200, 236, 228);
        font-size: 18px;
        text-decoration: none;
        transition: text-decoration 0.5s ease-in-out;
    }

    .header-link:hover {
        text-decoration: underline;
    }